
.css-1pqm26d-MuiAvatar-img{
    object-fit:contain !important;
}

.lesson-img .css-1hy9t21, .lesson-img .css-1pqm26d-MuiAvatar-img{
    object-fit:contain !important;
}

.list-table .MuiDataGrid-overlayWrapper{
    height: 25px;
}

.list-table .MuiDataGrid-cell,.list-table .MuiDataGrid-columnHeader{
    outline:0 !important;
}